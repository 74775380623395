import { useMatches } from "@remix-run/react";
import { useMemo } from "react";

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON} The router data
 *
 * https://github.com/sergiodxa/remix-util/blob/main/src/react/use-route-data.tsx
 */
export default function useRouteData<Data>(id: string): Data {
  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id]
  );

  // maybe we could create a useOptionalRouteData if needed?
  if (!route?.data) {
    return {} as Data; // as Data trick typescript.
  }

  return route?.data as Data;
}